.main-item2-root {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    aspect-ratio: 1;
    opacity: 0;
}

.main-item2-parent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding-top: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.main-item2-bg-icon {
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: box-shadow 200ms;
}

.main-item2-icon {
    aspect-ratio: 1;
    height: 42px;
}

.main-item2-title {
    color: white;
    padding: 0px;
    margin: 0px;
    font-family: Roboto-m;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
}

@keyframes slideInFromBottom {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}

@media (hover: hover) {
    .main-item2-root:hover {
        .main-item2-bg-icon {
            box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
        }
    }
}

/* Landscape */
@media (max-width: 1024px)
and (orientation: landscape) {
    .main-item2-icon {
        height: 44px;
    }
    .main-item2-title {
        font-size: 15px;
    }
}

/* Portrait */
@media (max-width: 912px)
and (orientation: portrait) {
    .main-item2-icon {
        height: 58px;
    }
    .main-item2-title {
        font-size: 19px;
    }
}

@media (max-width: 820px)
and (orientation: portrait) {
    .main-item2-icon {
        height: 55px;
    }
    .main-item2-title {
        font-size: 18px;
    }
}

@media (max-width: 768px)
and (orientation: portrait) {
    .main-item2-icon {
        height: 48px;
    }
    .main-item2-title {
        font-size: 15.5px;
    }
}

@media (max-width: 700px)
and (orientation: portrait) {
    .main-item2-icon {
        height: 40px;
    }
    .main-item2-title {
        font-size: 13px;
    }
}

@media (max-width: 540px)
and (orientation: portrait) {
    .main-item2-icon {
        height: 35px;
    }
    .main-item2-title {
        font-size: 11px;
    }
}

@media (max-width: 430px)
and (orientation: portrait) {
    .main-item2-icon {
        height: 32px;
    }
    .main-item2-title {
        font-size: 10.5px;
    }
}

@media (max-width: 375px)
and (orientation: portrait) {
    .main-item2-icon {
        height: 28px;
    }
    .main-item2-title {
        font-size: 9px;
    }
}
.header-root {
    display: flex;
    justify-content: space-between;
}

.header-logo {
    height: 60px;
}

/* Landscape */
@media (max-width: 1024px)
and (orientation: landscape) {
    .header-logo {
        height: 40px;
    }
}

/* Portrait */
@media (max-width: 912px)
and (orientation: portrait) {
    .header-logo {
        height: 55px;
    }
}

@media (max-width: 820px)
and (orientation: portrait) {
    .header-logo {
        height: 46px;
    }
}

@media (max-width: 768px)
and (orientation: portrait) {
    .header-logo {
        height: 40px;
    }
}

@media (max-width: 540px)
and (orientation: portrait) {
    .header-logo {
        height: 33px;
    }
}

@media (max-width: 430px)
and (orientation: portrait) {
    .header-logo {
        height: 32px;
    }
}

@media (max-width: 375px)
and (orientation: portrait) {
    .header-logo {
        height: 28px;
    }
}

@media (max-width: 350px)
and (orientation: portrait) {
    .header-logo {
        height: 26px;
    }
}
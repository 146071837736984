.loading-root {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.loading-root h2 {
    color: white;
    font-family: Roboto;
    font-size: 24px;
}

.loading-parent {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.loading-parent div {
    width: 8px;
    height: 5px;
    animation-name: resizeLoading;
    animation-duration: 550ms;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    border-radius: 2px;
}

.loading-parent div:first-child {
    background-color: var(--colorRed);
}

.loading-parent div:nth-child(2) {
    animation-delay: 100ms;
    background-color: var(--colorPurple);
}

.loading-parent div:nth-child(3) {
    animation-delay: 200ms;
    background-color: var(--colorGreen);
}

.loading-parent div:nth-child(4) {
    animation-delay: 300ms;
    background-color: var(--colorBlue);
}

.loading-parent div:last-child {
    animation-delay: 400ms;
    background-color: var(--colorOrange);
}


@keyframes resizeLoading {
    0%{
        height: 5px;
    }
    20%{
        height: 5px;
    }
    40%{
        height: 5px;
    }
    60%{
        height: 15px;
    }
    80%{
        height: 25px;
    }
    100%{
        height: 50px;
    }
}
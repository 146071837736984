.bg-root {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 0;
}

.gradient-top {
    width: var(--radialTopSize);
    height: var(--radialTopSize);
    position: fixed;
    left: var(--radialTopHalf);
    top: var(--radialTopHalf);
    background: radial-gradient(var(--colorRed) 0%, transparent 70%);
    animation-name: gradientScale;
    animation-duration: 500ms;
    animation-delay: 1.5s;
    scale: 0;
}

.gradient-right {
    width: var(--radialRightSize);
    height: var(--radialRightSize);
    position: fixed;
    right: calc((var(--radialRightSize) / 1.3) * -1);
    top: calc(50% - var(--radialRightHalf));
    background: radial-gradient(var(--colorOrange2), transparent 70%);
    animation-name: gradientScale;
    animation-duration: 400ms;
    animation-delay: 1.3s;
    scale: 0;
}

.bg-line {
    width: 100%;
    height: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: fixed;
    float: left;
    left: 0;
    bottom: 0;
    opacity: 0;
}

@keyframes gradientScale {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}

@keyframes lineShow {
    0% {
        opacity: 0;
        transform: translateY(30%);
        scale: 1.5;
    }
    100% {
        opacity: 0.3;
        transform: translateY(0);
        scale: 1;
    }
}

@media (max-width: 768px)
and (orientation: portrait) {
    .bg-line {
        height: 60%;
        background-position: bottom;
    }
}

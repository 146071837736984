.main-item-root {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    aspect-ratio: 1;
    opacity: 0;
    transition: scale 100ms, box-shadow 100ms;
}

.main-item-parent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.main-item-icon {
    aspect-ratio: 1;
    height: 50px;
}

.main-item-title {
    color: white;
    height: 100%;
    font-family: Roboto-m;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
}

@keyframes slideInFromBottom {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}

@media (hover: hover) {
    .main-item-root:hover {
        scale: 1.05;
        box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.25);
    }
}

/* Landscape */
@media (max-width: 1024px)
and (orientation: landscape) {
    .main-item-icon {
        height: 44px;
    }
    .main-item-title {
        font-size: 15px;
    }
}

/* Portrait */
@media (max-width: 912px)
and (orientation: portrait) {
    .main-item-icon {
        height: 58px;
    }
    .main-item-title {
        font-size: 19px;
    }
}

@media (max-width: 820px)
and (orientation: portrait) {
    .main-item-icon {
        height: 55px;
    }
    .main-item-title {
        font-size: 18px;
    }
}

@media (max-width: 768px)
and (orientation: portrait) {
    .main-item-icon {
        height: 48px;
    }
    .main-item-title {
        font-size: 15.5px;
    }
}

@media (max-width: 700px)
and (orientation: portrait) {
    .main-item-icon {
        height: 40px;
    }
    .main-item-title {
        font-size: 13px;
    }
}

@media (max-width: 540px)
and (orientation: portrait) {
    .main-item-icon {
        height: 35px;
    }
    .main-item-title {
        font-size: 11px;
    }
}

@media (max-width: 430px)
and (orientation: portrait) {
    .main-item-icon {
        height: 32px;
    }
    .main-item-title {
        font-size: 10.5px;
    }
}

@media (max-width: 375px)
and (orientation: portrait) {
    .main-item-icon {
        height: 28px;
    }
    .main-item-title {
        font-size: 9px;
    }
}
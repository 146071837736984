.footer-root {
    display: flex;
    justify-content: space-between;
}

.footer-root a {
    font-family: Roboto;
    background-color: var(--colorPage);
    padding: 8px 15px;
    border-radius: 8px;
    font-size: 14px;
    color: black;
}

.footer-root a b {
    font-family: Roboto-b;
}

@media (max-width: 900px)
and (orientation: portrait) {
    .footer-root {
        justify-content: center;
    }
    .footer-root a {
        font-size: 13px;
    }
}
.main-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
}

.main-parent {
    width: var(--mainWidth);
    max-width: var(--mainWidth);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-menu-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 2px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.main-menu2-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 2px;
    border-radius: 20px;
    position: relative;
}

@media (max-width: 912px) /* Portrait Mobile */
and (orientation: portrait) {
    .main-menu-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.App {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: var(--colorPage);
}

body {
  margin: 0;
}

/* Roboto */
@font-face {
  font-family: 'Roboto-l';
  font-weight: 200;
  src: local('Roboto'), url(./../fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto'), url(./../fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-i';
  font-weight: 400;
  src: local('Roboto'), url(./../fonts/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-m';
  font-weight: 400;
  src: local('Roboto'), url(./../fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-mi';
  font-weight: 400;
  src: local('Roboto'), url(./../fonts/Roboto-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-b';
  font-weight: 700;
  src: local('Roboto'), url(./../fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-bi';
  font-weight: 700;
  src: local('Roboto'), url(./../fonts/Roboto-BoldItalic.ttf) format('truetype');
}

/* Properties */
:root {
  --colorPage: rgb(246, 246, 244);
  --colorRed: rgb(232, 76, 61);
  --colorOrange: rgb(255, 161, 13);
  --colorOrange2: rgb(255, 93, 0);
  --colorGreen: rgb(41, 173, 97);
  --colorPurple: rgb(144, 69, 172);
  --colorBlue: rgb(52, 152, 219);

  --mainWidth: 900px;
  --videoPortrait: 350px;

  /* Radial Top Left*/
  --radialTopSize: 500px;
  --radialTopHalf: calc((var(--radialTopSize) / 1.85) * -1);

  /* Radial Center Right*/
  --radialRightSize: 500px;
  --radialRightHalf: calc(var(--radialRightSize) / 2);

}

/* Landscape */
@media (max-width: 1024px)
and (orientation: landscape) {
  :root {
    --mainWidth: 800px;
    --videoPortrait: 270px;
    --radialTopSize: 340px;
    --radialRightSize: 340px;
  }
}

/* Portrait */
@media (max-width: 1024px)
and (orientation: portrait) {
  :root {
    --videoPortrait: 600px;
  }
}

@media (max-width: 912px)
and (orientation: portrait) {
  :root {
    --mainWidth: 640px;
    --videoPortrait: 560px;
    --radialTopSize: 450px;
    --radialRightSize: 450px;
  }
}

@media (max-width: 820px)
and (orientation: portrait) {
  :root {
    --mainWidth: 580px;
    --videoPortrait: 500px;
    --radialTopSize: 420px;
    --radialRightSize: 420px;
  }
}

@media (max-width: 768px)
and (orientation: portrait) {
  :root {
    --mainWidth: 500px;
    --videoPortrait: 420px;
    --radialTopSize: 380px;
    --radialRightSize: 380px;
  }
}

@media (max-width: 700px)
and (orientation: portrait) {
  :root {
    --mainWidth: 420px;
  }
}

@media (max-width: 540px)
and (orientation: portrait) {
  :root {
    --mainWidth: 360px;
    --videoPortrait: 320px;
    --radialTopSize: 290px;
    --radialRightSize: 290px;
  }
}

@media (max-width: 430px)
and (orientation: portrait) {
  :root {
    --mainWidth: 340px;
    --videoPortrait: 300px;
    --radialTopSize: 290px;
    --radialRightSize: 290px;
  }
}

@media (max-width: 390px)
and (orientation: portrait) {
  :root {
    --videoPortrait: 280px;
  }
}

@media (max-width: 375px)
and (orientation: portrait) {
  :root {
    --mainWidth: 300px;
    --videoPortrait: 260px;
    --radialTopSize: 240px;
    --radialRightSize: 240px;
  }
}

@media (max-width: 350px)
and (orientation: portrait) {
  :root {
    --mainWidth: 290px;
    --videoPortrait: 250px;
    --radialTopSize: 220px;
    --radialRightSize: 220px;
  }
}

.banner-root {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.92);
    display: none;
    justify-content: center;
    align-items: center;
    animation-name: bannerOverlay;
    animation-duration: 400ms;
    z-index: 3;
}

.banner-parent {
    position: relative;
    display: none;
    animation-name: bannerImage;
    animation-duration: 350ms;
}

.banner-img {
    width: var(--mainWidth);
    position: relative;
    border-radius: 20px;
}

.banner-video {
    width: var(--videoPortrait);
    height: auto;
    position: relative;
    border-radius: 10px;
}

.banner-close {
    width: 30px;
    height: 30px;
    background-color: rgba(100, 100, 100, 0.8);
    border-radius: 50%;
    position: absolute;
    top: -30px;
    padding: 3px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

@media (hover: hover) {
    .banner-close:hover {
        background-color: rgba(100, 100, 100, 0.4);
    }
}

@keyframes bannerOverlay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bannerOverlayClose {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bannerImage {
    0% {
        opacity: 0;
        scale: 0.8;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}